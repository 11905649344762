import React from 'react'
import '../assets/Css/ServiceCardsCss.css';

export default function ServicesCards(props) {
    const { image, title, desc } = props;

    return (
        <div className="card m-3">
            <img src={image} className="card-img-top" alt={`${title}'s Image`} />
            <div className="card-body text-center">
                <h5 className="card-title">{title}</h5>
                <p className="card-text">{desc}</p>
            </div>
        </div>
    )
}
