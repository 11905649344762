import React from 'react'

import '../assets/Css/OurServices.css';

export default function OurServices() {

    return (
        <div style={{ position: 'relative' }}>
            <h1 className="main-heading">
                SCRAMBLENT
            </h1>
            <div className="positioned-container">
                <div className="mb-3">
                    <div className="row g-01">
                        <div className="col-md-6 left-aligner">
                            <div className="m-2 card-bodyy">
                                <div className="text-center m-5">
                                    <h5 className="card-title">Our</h5>
                                    <h1>Services</h1>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6" style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <div className="card-body m-2" style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <p className="card-text m-2">We are a premier technology solutions company dedicated to driving business success. Whether you're an enterprise, institution, or business, our tailored services—ranging from software development to IT strategy—provide a competitive edge to help you excel. Partner with us to optimize operations, enhance productivity, and achieve your strategic goals.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
