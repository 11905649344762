import React, { useEffect } from 'react'

import ServicesCards from './ServicesCards'
import Web from '../assets/websiteContent.png'
import Seo from '../assets/seoContent.png'
import Smm from '../assets/socialMedia.png'
import WelcomeDesc from './WelcomeDesc'
import OurServices from './OurServices'
import seoPage from '../assets/seo.png'
import { Link } from 'react-router-dom';
import '../assets/Css/Home.css';

const services = [
    {
        id: 1,
        image: Web,
        link: '/AcademicTutoring',
        title: "Academic Tutoring",
        desc: "Academic tutoring at Scramblent provides personalized support, enhancing students' understanding, confidence, and academic success across various subjects."
    },
    {
        id: 2,
        image: Smm,
        link: '/SocialMediaMarketing',
        title: "Social Media Marketing",
        desc: "Social media marketing (SMM) is an online marketing strategy that leverages different social media platforms to enhance marketing communication."
    },
    {
        id: 3,
        image: Seo,
        link: '/SearchEngineOptimization',
        title: "SEO Content",
        desc: "SEO content is crucial for driving relevant traffic to a website"
    }
]

export default function Home() {
    useEffect(() => {
        document.title = 'Scramblent | Home';
    }, []);
    return (
        <div className='home-container'>
            <div className="m-5" style={{ height: '250px' }}>
                <OurServices />
            </div>
            <div className="services-container">
                {services.map((elem) => (
                    <Link key={elem.id} to={elem.link} style={{ textDecoration: 'none' }}>
                        <ServicesCards
                            id={elem.id}
                            image={elem.image}
                            title={elem.title}
                            desc={elem.desc}
                        />
                    </Link>
                ))}
            </div>



            <div className='m-1' >
                <WelcomeDesc image={seoPage} heading1="Welcome to " heading2="SCRAMBLENT" para1="SCRAMBLENT was formed by merging teams from diverse fields, including management, finance, research, and IT. Each team member brings a wealth of experience and a proven track record of successfully completing numerous projects and delivering a wide range of products. Their meticulous attention to the unique needs of each client has been a cornerstone of their success." para2="SCRAMBLENT prides itself on offering professional services and software solutions backed by seasoned experts. Our core strength lies in our unwavering commitment to excellence and our deep-seated values, which we actively share with our clients. This dedication ensures that we not only meet but exceed expectations, fostering long-lasting and trustworthy partnerships." />
            </div>
        </div>


    )
}
