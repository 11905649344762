import React, { useEffect } from 'react'
import WelcomeDesc from './WelcomeDesc'
import academic from '../assets/academic.png'
import Offers from './Offers'
import WebsiteContent from './WebsiteContent';


import { Link } from 'react-router-dom';

const arr = [
    {
        id: 1,
        packageName: 'BASIC',
        price: 49,
        metric: 'Month',
        background: 'linear-gradient(to right, white, #99e7ff)',
        points: [
            'Personalized Learning Plans',
            'One-on-One Tutoring Sessions',
            'Group Tutoring Sessions',
            'Progress Tracking and Reporting',
            'Flexible Scheduling',
            'Online Tutoring Options',
            'Subject-Specific Expertise',
            'Homework Help',
            'Test Prep and Practice Exams',
            'Study Skills Enhancement',
        ]
    },
    {
        id: 2,
        packageName: 'PROFESSIONAL',
        price: 199,
        metric: 'Month',
        background: 'linear-gradient(to right, white, pink)',
        points: [
            'Personalized Learning Plans',
            'One-on-One Tutoring Sessions',
            'Group Tutoring Sessions',
            'Progress Tracking and Reporting',
            'Flexible Scheduling',
            'Online Tutoring Options',
            'Subject-Specific Expertise',
            'Homework Help',
            'Test Prep and Practice Exams',
            'Study Skills Enhancement',
            'Interactive Learning Tools',
            'Feedback and Support',
            'Parent-Teacher Communication',
            'Goal Setting and Achievement'


        ]
    },

]

export default function Academic() {
    useEffect(() => {
        document.title = 'Scramblent | Academic Tutoring';
    }, []);
    return (
        <>
            <WebsiteContent name="Academic Tutoring" />
            <div className='m-3' style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='m-1' >
                    <WelcomeDesc image={academic} heading2="ACADEMIC TUTORING" heading1="Discover services we’r provided" para1="In today’s educational landscape, students often need personalized support to excel academically. Academic tutoring provides targeted assistance in various subjects, helping students improve their understanding and performance. By focusing on individual needs, tutoring can enhance learning, build confidence, and support academic success." para2="Our academic tutoring services offer expert guidance across subjects like math, science, and language arts. With customized lesson plans and flexible scheduling, we cater to each student’s unique requirements, ensuring they receive the support they need to thrive in their studies." />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
                    {
                        arr.map((elem) => {
                            return (
                                <Link key={elem.id} to='/Contact' style={{ textDecoration: 'none', color: 'black' }}>
                                    <div className='overall m-4'  style={{
                                        background: `${elem.background}`,
                                        border: '1px solid black',
                                        borderRadius: '20px'
                                    }}
                                    >
                                        <Offers packages={elem.points} price={elem.price} name={elem.packageName}
                                            metric={elem.metric}
                                        />
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>

            </div>
        </>

    )
}
