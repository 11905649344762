import React from 'react'

import '../assets/Css/Reviews.css';

export default function Reviews(props) {
    const { image, para1, heading1 } = props
    return (
        <div className="mb-3" >
            <div className="row g-0">
                <div className="col-md-4 review-img">
                    <img src={image} style={{
                        height: '15vh',
                        width: '150px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }} className="img-fluid rounded-start m-5" alt="Review Person" />
                </div>
                <div className="col-md-8" style={{
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'start',
                }}>
                    <div className="card-body m-5" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'start',
                        alignItems: 'start'
                    }}>
                        <h2 className="card-title text-center">{heading1}</h2>
                        <hr style={{
                            border: '0',
                            borderTop: '5px solid #000',
                            marginTop: '10px',
                            marginBottom: '10px',
                            width: '10%'
                        }} />
                        <p className="card-text"><i>"{para1}"</i></p>
                    </div>
                </div>
            </div>
        </div>
    )
}
