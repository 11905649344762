import React from 'react';
import Company1 from '../assets/company1.png';
import Company2 from '../assets/company2.png';
import Company3 from '../assets/company3.png';
import Company4 from '../assets/company4.png';
import Company5 from '../assets/company5.png';
const companies = [

    {
        id: 1,
        image: Company4
    },
    {
        id: 2,
        image: Company1
    },
    {
        id: 3,
        image: Company2
    },
    {
        id: 4,
        image: Company5
    },

    {
        id: 5,
        image: Company3
    },
];

export default function Companies() {
    return (
        <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px'
        }}>
            {
                companies.map((elem) => (
                    <img
                        src={elem.image}
                        key={elem.id}
                        alt="Company Logo"
                        style={{
                            maxWidth: '300px',
                            height: 'auto',
                        }}
                    />
                ))
            }
        </div>
    );
}
