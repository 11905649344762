import React from 'react'
import logo from '../assets/download.png'
import facebook from '../assets/facebook.png'
import whatsapp from '../assets/whatsapp.png'

import uk from '../assets/UKflag.png'
import pk from '../assets/PKflag.png'
import { Link } from 'react-router-dom'

import '../assets/Css/BottomNavbar.css';
const usefulLinks = [

    {
        id: 3,
        link: 'Website Content',
        redirection: '/WebsiteContent'
    },
    {
        id: 4,
        link: 'SEO Content',
        redirection: '/SearchEngineOptimization'
    },
    {
        id: 1,
        link: 'Academic Tutoring',
        redirection: '/AcademicTutoring'
    },
    {
        id: 2,
        link: 'Graphic Designing',
        redirection: '/GraphicDesigning'
    },
    {
        id: 5,
        link: 'Social Media',
        redirection: '/SocialMediaMarketing'
    },
]
const getInTouch = [
    {
        id: 1,
        heading: 'Email',
        text: 'info@scramblent.com'
    },
    {
        id: 2,
        heading: 'Open Hours',
        text: '10:00 AM - 10:00 PM'
    }
]
const serviceCountries = [
    {
        id: 2,
        flag: uk,
        country: 'UK Address',
        address: 'Lytchett House, 13 Freeland Park, Wareham Road, Poole, Dorset, BH16 6FA.',
        phoneNumber: ''
    },
    {
        id: 4,
        flag: pk,
        country: 'PK Address',
        address: 'Office 702, Progressive Square, Shahra-e-Faisal Karachi, Pakistan.',
        phoneNumber: ''
    }
]

const phoneNumber = '+923102192625';
const message = 'Hello SCRAMBLENT!, I need assistance';
const encodedMessage = encodeURIComponent(message);
const facebookPageUrl = 'https://www.facebook.com/ad_center/create/ad/?entry_point=www_profile_plus_left_nav_promote_button&page_id=371061432765691';

export default function BottomNavbar() {
    return (
        <div className='topnav'>
            <div className='flex-container' style={{ display: 'flex', flex: 1 }}>
                <div className='flex-item'>
                    <div className='flex-content'>
                        <img className="responsive-logo" src={logo} alt="Main Logo" width="600" />
                    </div>
                    <div className='flex-content'>
                        <h6 className="responsive-text">
                            Scramblent, an IT company established in 2016, has grown steadily over the years. With extensive experience, our team has honed its expertise in digital marketing to an exceptional level.
                        </h6>
                    </div>
                    <div className='flex-content'>
                        <a
                            href={facebookPageUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                className='m-3'
                                src={facebook}
                                alt="Visit our Facebook page"
                                height="40"
                            />
                        </a>
                        <a
                            href={`https://wa.me/${phoneNumber}?text=${encodedMessage}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                className='m-3'
                                src={whatsapp}
                                alt="Chat with us on WhatsApp"
                                height="40"
                            />
                        </a>
                    </div>
                </div>
                <div style={{ flex: 2 / 6, }}>
                    <h5 style={{ textAlign: 'center' }}>Useful Links</h5>
                    <ul >
                        {
                            usefulLinks.map((elem, index) => {
                                return (
                                    <div className='flex-itemm' key={index} >
                                        <Link to={elem.redirection} style={{ textDecoration: 'none', color: 'black' }}>
                                            <li className="list-group-item">{elem.link}</li>
                                        </Link>
                                        <hr />
                                    </div>

                                );
                            })
                        }
                    </ul>
                </div>
                <div style={{ flex: 2 / 6, }}>
                    <h5 style={{ textAlign: 'center' }}>Get In Touch</h5>
                    <ul >
                        {
                            getInTouch.map((elem, index) => {
                                return (
                                    <div className='flex-itemm' key={index} >
                                        <li className="list-group-item" ><h6>{elem.heading}: </h6>{elem.text}</li>
                                        <hr />
                                    </div>
                                );
                            })
                        }
                    </ul>
                </div>
            </div>
            <div className="m-3" style={{
                display: 'flex', flex: 1,
                borderBottom: '1px solid grey'
            }}>
                {
                    serviceCountries.map((elem) => {
                        return (
                            <div key={elem.id} style={{ flex: 1 / 2 }}>
                                <div className="m-2" style={{ flex: 1 / 4 }}>
                                    <img className="m-1" src={elem.flag} alt={elem.country} width="30" />
                                    <h6>{elem.country}: </h6>
                                    <p className='parasize'>{elem.address}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', height: '7vh', alignItems: 'center' }}>
                <div style={{ flex: 1 / 2 }}>
                    <h6 className="parasize m-2">2016 - 2024 Scramblent LLC . All Rights Reserved.</h6>
                </div>
                <div style={{ flex: 1 / 2, display: 'flex', flexDirection: 'row', alignItems: 'end', justifyContent: 'end' }}>
                    <Link to='/Terms' style={{ textDecoration: 'none', color: 'black' }}>
                        <h6 className=" parasize m-2">Terms Of Use</h6>
                    </Link>
                    <Link to='/Privacy' style={{ textDecoration: 'none', color: 'black' }}>
                        <h6 className=" parasize m-2">Privacy Policy</h6>
                    </Link>
                    <Link to='/Refund' style={{ textDecoration: 'none', color: 'black' }}>
                        <h6 className="parasize m-2">Refund Policy</h6>
                    </Link>
                </div>
            </div>


        </div>
    )
}
