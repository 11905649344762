import React, { useEffect } from 'react'
import WelcomeDesc from './WelcomeDesc'
import webPage from '../assets/web.png'
import Offers from './Offers'
import WebsiteContent from './WebsiteContent';

import { Link } from 'react-router-dom';
const arr = [
    {
        id: 1,
        packageName: 'BASIC',
        price: 0.068,
        metric: 'Week',
        background: 'linear-gradient(to right, white, #99e7ff)',
        points: [
            "Simple Product Description",
            'SEO Content',
            'Descriptions for Blogs',
            'Simple editorial content'
        ]
    },
    {
        id: 2,
        packageName: 'BUSINESS',
        price: 0.16,
        metric: 'Week',
        background: 'linear-gradient(to right, white, pink)',
        points: [
            'Specialised or high volumes of content',
            'Technical data sheets',
            'Press releases',
            'Editorial content',
            'B2B content marketing'
        ]
    },

]

export default function WebsitePage() {
    useEffect(() => {
        document.title = 'Scramblent | Website Content';
    }, []);
    return (
        <>
            <WebsiteContent name="Website Content" />
            <div className='m-3' style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='m-1' >
                    <WelcomeDesc image={webPage} heading2="WEBSITE CONTENT" heading1="Discover services we’r provided" para1="In today's competitive market, many businesses struggle to generate leads and drive traffic to their websites. The key to overcoming this challenge lies in optimizing and managing website content through strategic keyword analysis. By ensuring that your website is aligned with the search terms your potential customers are using, you can significantly enhance your online visibility and attract more qualified leads." para2="Our content writing services provide engaging, unique content crafted by skilled writers. We ensure your website resonates with your audience and aligns with your brand, driving results and helping your business stand out." />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
                    {
                        arr.map((elem) => {
                            return (
                                <Link key={elem.id} to='/Contact' style={{ textDecoration: 'none', color: 'black' }}>
                                    <div className='overall m-4' style={{
                                        background: `${elem.background}`,
                                        border: '1px solid black',
                                        borderRadius: '20px'
                                    }}
                                    >
                                        <Offers packages={elem.points} price={elem.price} name={elem.packageName}
                                            metric={elem.metric}
                                        />
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>

            </div>
        </>

    )
}
