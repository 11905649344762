import React from 'react'

import uk from '../assets/UKflag.png'
import pk from '../assets/PKflag.png'
const serviceCountries = [
    {
        id: 2,
        flag: uk,
        country: 'UK Address',
        address: 'Lytchett House, 13 Freeland Park, Wareham Road, Poole, Dorset, BH16 6FA.',
        phoneNumber: ''
    },
    {
        id: 4,
        flag: pk,
        country: 'PK Address',
        address: 'Office 702, Progressive Square, Shahra-e-Faisal Karachi, Pakistan.',
        phoneNumber: ''
    }
]
export default function ContactUs(props) {
    const { image, heading1, heading2 } = props
    return (
        <div className="mb-3">
            <div className="row g-0">
                <div className="col-lg-6 col-md-8 col-sm-12 d-flex justify-content-center align-items-center">
                    <div className="card-body text-center m-5">

                        <h5 className="card-title text-center">{heading1}</h5>
                        <h1 className="text-center">{heading2}</h1>
                        <hr style={{
                            border: '0',
                            borderTop: '5px solid #000',
                            marginTop: '10px',
                            marginBottom: '10px',
                            marginLeft: '45%',
                            width: '10%'
                        }} />
                        <div className="m-1" style={{
                            display: 'flex', flex: 1,
                        }}>
                            {
                                serviceCountries.map((elem) => {
                                    return (
                                        <div key={elem.id} style={{ flex: 1 / 2 }}>
                                            <div className="m-3" style={{ flex: 1 / 4 }}>
                                                <img className="m-1" src={elem.flag} alt={elem.country} width="30" />
                                                <h6>{elem.country}: </h6>
                                                <p>{elem.address}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <h3 className="card-title text-center" style={{ fontStyle: 'italic' }}>
                            info@scramblent.com
                        </h3>

                    </div>
                </div>
                <div className="col-lg-6 col-md-4 col-sm-12 d-flex justify-content-center align-items-center">
                    <img src={image}
                        className="img-fluid rounded-start"
                        alt="Contact Us"
                        style={{
                            width: '100%',
                            maxWidth: '500px',
                        }} />
                </div>

            </div>
        </div>
    )
}
