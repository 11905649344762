import React, { useEffect } from 'react'
import WelcomeDesc from './WelcomeDesc'
import seoPage from '../assets/seo.png'
import Offers from './Offers'
import WebsiteContent from './WebsiteContent';
import { Link } from 'react-router-dom';
const arr = [
    {
        id: 1,
        packageName: 'BASIC',
        price: 299,
        metric: 'Month',
        background: 'linear-gradient(to right, white, #99e7ff)',
        points: [
            '2 Pages',
            '4 Keypharases',
            '2 Pages Content',
            '2 Guest Blog Posting',
            'Detailed Keyword Analysis',
            'On-Page analysis',
            '1 Internal Blog',
            'Google Analytics',
            'Google Indexing'
        ]
    },
    {
        id: 2,
        packageName: 'PROFESSIONAL',
        price: 549,
        metric: 'Month',
        background: 'linear-gradient(to right, white, pink)',
        points: [
            '5 Pages',
            '10 Keypharases',
            '5 Pages Content',
            '5 Guest Blog Posting',
            'Detailed Keyword Analysis',
            'On-Page analysis',
            '5 Internal Blog',
            'Google Analytics',
            'Google Indexing'
        ]
    },

]

export default function SeoPage() {
    useEffect(() => {
        document.title = 'Scramblent | SEO';
    }, []);
    return (
        <>
            <WebsiteContent name="SEO Content" />
            <div className='m-3' style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='m-1' >
                    <WelcomeDesc image={seoPage} heading2="SEARCH ENGINE OPTIMIZATION" heading1="Discover services we’r provided" para1="In today’s competitive market, businesses often struggle to generate leads and drive traffic. The solution lies in optimizing website content with effective SEO strategies, including thorough keyword analysis. By aligning your content with the right search terms, you can boost your online visibility and attract more potential customers." para2="To achieve this, it’s essential to work with a professional SEO team. We specialize in creating tailored content that engages readers and improves search rankings. Our team of experts ensures your website stands out, turning visitors into loyal customers and helping your business grow." />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>

                    {
                        arr.map((elem) => {
                            return (
                                <Link key={elem.id} to='/Contact' style={{ textDecoration: 'none', color: 'black' }}>
                                    <div className='overall m-4'  style={{
                                        background: `${elem.background}`,
                                        border: '1px solid black',
                                        borderRadius: '20px'
                                    }}
                                    >
                                        <Offers packages={elem.points} price={elem.price} name={elem.packageName}
                                            metric={elem.metric}
                                        />
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>


            </div>
        </>

    )
}
