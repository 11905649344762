import React, { useEffect } from 'react'
import WebsiteContent from './WebsiteContent';
import '../assets/Css/BottomSecondSame.css';

const arr = [
    {
        id: 1,
        heading: 'WHAT INFORMATION WE COLLECT',
        content: 'We collect information you provide directly to us. For example, we collect information when you submit data via our contact form, sign up to receive our emails, communicate with us on the Platforms or via third-party social media sites, or order our products or services. The type of information we may collect include your:',
        bullets: [
            'Name',
            'Email Address',
            'Products Ordered',
            'Any other information you choose to provide'
        ]
    },
    {
        id: 2,
        heading: 'WHAT WE USE YOUR INFORMATION FOR',
        content: 'We use collected information in a variety of ways, including to:',
        bullets: [
            'Provide the products and services you request',
            'Process transactions and send you related information (i.e., confirmations and receipts);',
            'Respond to your comments, questions and requests and provide customer service;',
            'Communicate with you about products, services, offers, promotions, and provide news and information we think will be of interest to you;',
            'Send you technical notices, updates, security alerts, and support and administrative messages;',
            'Better tailor our Platforms to your interests and needs;',
            'Carry out profiling activities in order to learn more about you; and',
            'Offer you tailored advertising based on your behavior on our Platforms.'
        ]
    },
    {
        id: 3,
        heading: 'YOUR RIGHTS',
        content: 'Under the General Data Protection Regulation, you may have a number of important rights free of charge. Please note that for each of the rights below we may have valid legal reasons to refuse your request. In such instances, we will let you know if that is the case. In summary, your rights include:',
        bullets: [
            'Fair processing of information and transparency over how we use your use personal information;',
            'Access to your personal information and to certain other supplementary information that this Policy is already designed to address;',
            'Require us to correct any mistakes in your information which we hold;',
            'Require the erasure of personal information concerning you in certain situations;',
            'Receive the personal information concerning you which you have provided to us, in a structured, commonly used and machine-readable format and have the right to transmit those data to a third party in certain situations;',
            'Object at any time to processing of personal information concerning you for direct marketing;',
            'Object to decisions being taken by automated means which produce legal effects concerning you or similarly significantly affect you;',
            'Object in certain other situations to our continued processing of your personal information;',
            'Otherwise restrict our processing of your personal information in certain circumstances.'
        ]
    }
]

export default function PrivacyPolicy() {
    useEffect(() => {
        document.title = 'Scramblent | Privacy Policy';
    }, []);
    return (
        <>
            <WebsiteContent name="Privacy Policy" />
            <div className='container' style={{
                width: '80vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginTop: '30px'
            }}>
                {arr.map((elem) => (
                    <div className='m-2' key={elem.id}>
                        <h4>{elem.heading}</h4>
                        <p className='marginal2' style={{ fontStyle: 'italic' }}>{elem.content}</p>
                        <ul className="custom-list">
                            {elem.bullets.map((element, index) => {
                                return (
                                    <li key={index}>{element}</li>
                                );
                            })}
                        </ul>

                        <hr></hr>
                    </div>
                ))}
            </div>
        </>

    )
}
