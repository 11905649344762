import React, { useEffect } from 'react'
import WelcomeDesc from './WelcomeDesc'
import graphic from '../assets/graphic.png'
import Offers from './Offers'
import WebsiteContent from './WebsiteContent';


import { Link } from 'react-router-dom';

const arr = [
    {
        id: 1,
        packageName: 'BASIC',
        price: 199,
        metric: 'Month',
        background: 'linear-gradient(to right, white, #99e7ff)',
        points: [
            'Logo Design and Branding',
            'Business Card Design',
            'Brochure and Flyer Design',
            'Social Media Post Templates',
            'Website and App UI Design',
            'Custom Illustrations',
            'Infographic Design',
            'Banner and Poster Design',
        ]
    },
    {
        id: 2,
        packageName: 'PROFESSIONAL',
        price: 499,
        metric: 'Month',
        background: 'linear-gradient(to right, white, pink)',
        points: [
            'Logo Design and Branding',
            'Business Card Design',
            'Brochure and Flyer Design',
            'Social Media Post Templates',
            'Website and App UI Design',
            'Custom Illustrations',
            'Infographic Design',
            'Banner and Poster Design',
            'Presentation Design (PowerPoint/Keynote)',
            'Email Newsletter Design',
            'Packaging Design',
            'Print Ad Design',
            'Motion Graphics for Social Media',
            'Brand Style Guide Creation'

        ]
    },

]

export default function GraphicDesign() {
    useEffect(() => {
        document.title = 'Scramblent | Graphic Designing';
    }, []);
    return (
        <>
            <WebsiteContent name="Graphic Designing" />
            <div className='m-3' style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='m-1' >
                    <WelcomeDesc image={graphic} heading2="GRAPHIC DESIGNING" heading1="Discover services we’r provided" para1="SCRAMBLENT was formed by merging teams from diverse fields, including management, finance, research, and IT. Each team member brings a wealth of experience and a proven track record of successfully completing numerous projects and delivering a wide range of products. Their meticulous attention to the unique needs of each client has been a cornerstone of their success." para2="SCRAMBLENT prides itself on offering professional services and software solutions backed by seasoned experts. Our core strength lies in our unwavering commitment to excellence and our deep-seated values, which we actively share with our clients. This dedication ensures that we not only meet but exceed expectations, fostering long-lasting and trustworthy partnerships." />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
                    {
                        arr.map((elem) => {
                            return (
                                <Link key={elem.id} to='/Contact' style={{ textDecoration: 'none', color: 'black' }}>
                                    <div className='overall m-4'  style={{
                                        background: `${elem.background}`,
                                        border: '1px solid black',
                                        borderRadius: '20px'
                                    }}
                                    >
                                        <Offers packages={elem.points} price={elem.price} name={elem.packageName}
                                            metric={elem.metric}
                                        />
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>

            </div>
        </>

    )
}
