import React, { useEffect } from 'react'
import WebsiteContent from './WebsiteContent';
import '../assets/Css/BottomSecondSame.css';

const arr = [
    {
        id: 1,
        heading: 'The terms “we”, “us”, and “our” refer to Scramblent.',
        content: 'By using our Site, scramblent.com ("Site(s)"), or any of our services, including software development, web design, web development, and content services, you ("user," "customer," "you," or "your") agree to abide by our Terms of Service, Privacy Policy, and any other agreements governing your use of the Site or Service (collectively, "Agreements"). If you do not agree with the terms and conditions outlined in these Agreements, you should refrain from using our Site or Service.'
    },
    {
        id: 2,
        heading: 'LAWFUL PURPOSES',
        content: 'ou may use the Site and/or Service solely for lawful purposes and agree to be financially responsible for all purchases made by you. You also agree to use the Site and/or Service, including any purchases of services or products, for legitimate purposes only. You must not post or transmit any material through the Site and/or Service that infringes the rights of others, is threatening, abusive, defamatory, libelous, invasive of privacy or publicity rights, vulgar, obscene, profane, or otherwise objectionable. Additionally, you must not share content that contains harmful formulas, recipes, or instructions, or that encourages conduct constituting a criminal offense, gives rise to civil liability, or otherwise violates any law.'
    },
    {
        id: 3,
        heading: 'REFUSAL OF SERVICE',
        content: 'We reserve the right to deny access to the Site and/or Service to any person or entity at our discretion, without the need to provide a reason. If you violate these Terms of Service or any other Agreements governing your use of the Site and/or Service, we reserve the right to remove you immediately, without refund.'
    },
    {
        id: 4,
        heading: 'DISCLAIMER',
        content: 'The Company may share the successful outcomes of its users or customers on the Site and/or Service, but these examples should not be interpreted as promises or guarantees of your own results, future earnings, business profit, marketing performance, audience growth, or any other outcomes. By accessing the Site and/or Service, you acknowledge and agree that you are solely responsible for your own progress and results. We do not guarantee that you will achieve any specific results by using our ideas, tools, strategies, or recommendations, and nothing on our Site and/or Service constitutes a promise, warranty, or guarantee of such outcomes.'
    },
    {
        id: 5,
        heading: 'ERRORS, INACCURACIES, AND OMISSIONS',
        content: 'The information provided on the Site and/or Service is subject to change, and the Company makes no representations or warranties regarding its accuracy, completeness, reliability, currency, or error-free status, regardless of the source. The Company disclaims all liability for any inaccuracies, errors, or omissions in the information provided.'
    },
    {
        id: 6,
        heading: 'RECOVERY OF LITIGATION EXPENSES',
        content: 'If any legal action, arbitration, or other proceeding is initiated to enforce the Terms of Service, or due to an alleged dispute, breach, default, or misrepresentation related to any of its provisions, the prevailing party shall be entitled to recover reasonable attorneys\' fees and other costs incurred in the action or proceeding, in addition to any other relief to which they may be entitled.'
    },
    {
        id: 7,
        heading: 'EFFECT OF HEADINGS',
        content: 'The subject headings in the paragraphs of the Terms of Service are provided for convenience only and shall not influence the construction or interpretation of any of its provisions.'
    },
    {
        id: 8,
        heading: 'ENTIRE AGREEMENT',
        content: 'These Agreements, which include the Terms of Service, the referenced Privacy Policy, and any other written agreements governing your use of our Site and/or Service, constitute the entire agreement between you and the Company regarding the Site and/or Service. They supersede all prior or contemporaneous communications and proposals, whether electronic, oral, or written, between you and the Company concerning the Site and/or Service.'
    },
    {
        id: 9,
        heading: 'WAIVER',
        content: 'The Company’s waiver of any provision within these Terms of Service shall not be considered or construed as a waiver of any other provision, whether similar or not, nor shall it be deemed a continuing waiver. No waiver shall be binding unless it is provided in writing and signed by an authorized representative of the Company.'
    },
    {
        id: 10,
        heading: 'SEVERABILITY',
        content: 'If any term, provision, covenant, or condition of the Terms of Service is found to be invalid, void, or unenforceable by an arbitrator or a court of competent jurisdiction, the remaining provisions of the Terms of Service shall remain in full force and effect and shall not be affected, impaired, or invalidated in any way.'
    },
    {
        id: 11,
        heading: 'ASSIGNMENT',
        content: 'These Terms of Service are binding upon and shall benefit the successors and assigns of the parties involved. You may not assign, delegate, sublicense, or transfer these Terms of Service in any way. Any attempt by you to transfer, assign, delegate, or sublicense these Terms is considered invalid.'
    },
    {
        id: 12,
        heading: 'CHANGED TERMS',
        content: 'We reserve the right to update any part of our Site and/or Service, including these Terms of Service, at any time. Amendments take effect immediately upon notice, which will be given by posting the revised Terms of Service on the Site. If you have provided us with your email address, we will also notify you of significant changes via email. Continued use of the Site and/or Service after an amendment constitutes your acceptance of the changes. The most recent versions of the Terms of Service will be posted on the Site, along with their effective dates.'
    },
    {
        id: 13,
        heading: 'HOW TO CONTACT US',
        content: 'If you have any questions about this Terms of Service, please contact us at: info@scramblent.com | Contact number: +92 310 2192625'
    },
]

export default function TermsOfUse() {
    useEffect(() => {
        document.title = 'Scramblent | Terms of Use';
    }, []);
    return (
        <>
            <WebsiteContent name="Terms Of Use" />
            <div className='container' style={{
                width: '80vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginTop: '30px'
            }}>
                {arr.map((elem) => (
                    <div className='m-2' key={elem.id}>
                        <h4>{elem.heading}</h4>
                        <p className='marginal2' style={{ fontStyle: 'italic' }}>{elem.content}</p>
                        <hr></hr>
                    </div>
                ))}
            </div>
        </>

    )
}
