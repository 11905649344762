import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import BottomSame from './components/BottomSame';
import Home from './components/Home';
import Navbar from './components/Navbar';
import SeoPage from './components/SeoPage';
import SmmPage from './components/SmmPage';
import WebsitePage from './components/WebsitePage';
import ContactPage from './components/ContactPage';
import AboutPage from './components/AboutPage';
import BottomSecondSame from './components/BottomSecondSame';
import Academic from './components/Academic';
import GraphicDesign from './components/GraphicDesign';
import TermsOfUse from './components/TermsOfUse';
import PrivacyPolicy from './components/PrivacyPolicy';
import RefundPolicy from './components/RefundPolicy';
import ScrollToTop from './components/ScrollToTop';
function ConditionalBottomSecondSame() {
  const location = useLocation();
  if (location.pathname === "/Terms" || location.pathname === "/Privacy" || location.pathname === "/Refund") {
    return null;
  }

  return <BottomSecondSame />;
}

function App() {
  return (
    <Router>

      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/WebsiteContent" element={<WebsitePage />} />
        <Route path="/SearchEngineOptimization" element={<SeoPage />} />
        <Route path="/SocialMediaMarketing" element={<SmmPage />} />
        <Route path="/GraphicDesigning" element={<GraphicDesign />} />
        <Route path="/AcademicTutoring" element={<Academic />} />
        <Route path="/About" element={<AboutPage />} />
        <Route path="/Contact" element={<ContactPage />} />
        <Route path="/Terms" element={<TermsOfUse />} />
        <Route path="/Privacy" element={<PrivacyPolicy />} />
        <Route path="/Refund" element={<RefundPolicy />} />
      </Routes>
      <ConditionalBottomSecondSame />
      <BottomSame />
    </Router>
  );
}

export default App;
