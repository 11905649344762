import React from 'react'
import tick from '../assets/accept.png'

import '../assets/Css/Offers.css';
export default function Offers(props) {
    const { packages, price, name, metric } = props
    return (
        <>
            <div className='container' style={{ display: 'flex', height: '8vh', justifyContent: 'center', alignItems: 'center' }}>
                <h3 className='offers-h3' style={{ flex: 1, textAlign: 'center' }}>{name}</h3>
                <div style={{ flex: 1, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h3 className='offers-h3'>${price}</h3>
                    <h5 className='offers-h5' style={{ margin: 0, alignSelf: 'flex-end' }}>/{metric}</h5>
                </div>
            </div>
            <ul >
                {
                    packages.map((elem, index) => {
                        return (
                            <React.Fragment key={index}>
                                <li className="list-group-item offers-list-item">
                                    <img src={tick} className="img-fluid rounded-start m-1" alt="Tick Logo" />{elem}</li>
                                <hr />
                            </React.Fragment>
                        );
                    })
                }
            </ul>
        </>
    )
}
