import React from 'react'
import BottomNavbar from './BottomNavbar'
import SuccessMetric from './SuccessMetric'
import background from '../assets/world.jpeg'
import client from '../assets/customer.png'
import awards from '../assets/award-symbol.png'
import check from '../assets/check-mark.png'
import layers from '../assets/layers.png'

import '../assets/Css/BottomSame.css';

const successMetric = [
    {
        id: 1,
        name: 'Active Clients',
        count: 150,
        image: client,
        adder: '+'
    },
    {
        id: 2,
        name: 'Success Rate',
        count: 98,
        image: check,
        adder: '%'
    },
    {
        id: 3,
        name: 'Projects Done',
        count: 86,
        image: layers,
        adder: '+'
    },
    {
        id: 4,
        name: 'Awards',
        count: 35,
        image: awards,
        adder: '+'
    }
]
export default function BottomSame() {
    return (
        <>
            <div
                className="responsive-container"
                style={{
                    backgroundColor: '#e59edd',
                    position: 'relative',
                    width: '80vw',
                    borderBottomRightRadius: '5vw',
                    borderTopRightRadius: '5vw'
                }}
            >
                <img
                    src={background}
                    alt="Background Image"
                    className="responsive-image"
                    style={{
                        height: '400px',
                        width: '80vw',
                        alignSelf: 'center',
                        justifySelf: 'center',
                        opacity: 0.7
                    }}
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        top: '35%',
                    }}
                >
                    {successMetric.map((elem, index) => {
                        return (
                            <SuccessMetric
                                key={elem.id}
                                name={elem.name}
                                image={elem.image}
                                count={elem.count}
                                adder={elem.adder}
                                isFirst={index === 0}
                            />
                        );
                    })}
                </div>
            </div>



            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <BottomNavbar />
            </div>
        </>
    )
}
