import React, { forwardRef } from 'react';
import logo from '../assets/download.png';
import cross from '../assets/cross.png';
import { Link } from 'react-router-dom';
import '../assets/Css/ToggleNavbar.css';


const ToggleBarNav = forwardRef(({ closeMenu, className }, ref) => {
    return (
        <div ref={ref} className={`toggle-bar-nav ${className}`}>
            <div style={{ textAlign: 'right' }}>
                <img
                    className='m-3 cross'
                    src={cross}
                    alt="Close Menu"
                    onClick={closeMenu}
                    style={{ cursor: 'pointer' }}
                />
                <hr style={{ marginLeft: 'auto' }} />
            </div>
            <ul className="navbar-nav m-3">
                <li className="nav-item">
                    <Link className="nav-link active navbar-fontss" aria-current="page" to='/' style={{ textDecoration: 'none', textAlign: 'right' }} onClick={closeMenu}>
                        HOME
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link navbar-fontss" to='/About' style={{ textDecoration: 'none', textAlign: 'right' }} onClick={closeMenu}>
                        ABOUT US
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link navbar-fontss" to='/WebsiteContent' style={{ textDecoration: 'none', textAlign: 'right' }} onClick={closeMenu}>
                        WEBSITE CONTENT
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link navbar-fontss" to='/SearchEngineOptimization' style={{ textDecoration: 'none', textAlign: 'right' }} onClick={closeMenu}>
                        SEO CONTENT
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link navbar-fontss" to='/AcademicTutoring' style={{ textDecoration: 'none', textAlign: 'right' }} onClick={closeMenu}>
                        ACADEMIC TUTORING
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link navbar-fontss" to='/GraphicDesigning' style={{ textDecoration: 'none', textAlign: 'right' }} onClick={closeMenu}>
                        GRAPHIC DESIGNING
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link navbar-fontss" to='/SocialMediaMarketing' style={{ textDecoration: 'none', textAlign: 'right' }} onClick={closeMenu}>
                        SOCIAL MEDIA
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link navbar-fontss" to='/Contact' style={{ textDecoration: 'none', textAlign: 'right' }} onClick={closeMenu}>
                        CONTACT US
                    </Link>
                </li>
            </ul>

            <div style={{ textAlign: 'right' }}>
                <hr style={{ marginLeft: 'auto' }} />
                <Link className="navbar-brand" to='/'>
                    <img
                        className='m-3 navbar-logo'
                        src={logo}
                        alt="Scramblent Logo"
                        height="90px"
                    />
                </Link>

            </div>
        </div>
    )
});

export default ToggleBarNav;
