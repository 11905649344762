import React, { useState, useEffect, useRef } from 'react';

import '../assets/Css/SuccessMetric.css';

export default function SuccessMetric(props) {
    const { name, count, image, adder, isFirst } = props;
    const [currentCount, setCurrentCount] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const handleScroll = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            });
        };

        const observer = new IntersectionObserver(handleScroll, { threshold: 0.1 });
        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    useEffect(() => {
        let start = 0;
        const end = count;
        const duration = 10000;
        const stepTime = 100;
        const steps = Math.ceil(duration / stepTime);
        const stepValue = (end - start) / steps;
        let timer;

        const countUp = () => {
            start += stepValue;
            if (start >= end) {
                setCurrentCount(end);
                cancelAnimationFrame(timer);
            } else {
                setCurrentCount(Math.ceil(start));
                timer = requestAnimationFrame(countUp);
            }
        };

        if (isVisible) {
            setCurrentCount(0);
            timer = requestAnimationFrame(countUp);
        } else {
            setCurrentCount(0);
        }

        return () => {
            cancelAnimationFrame(timer);
        };
    }, [isVisible, count]);

    return (
        <div ref={ref} className={`success-metric ${isFirst ? 'first-metric' : ''}`} style={{ marginLeft: isFirst ? '25px' : '' }}>
            <div className="row g-0">
                <div className="col-md-4">
                    <img src={image} alt="Success Metric Image" />
                </div>
                <div className="col-md-8">
                    <div className="card-body">
                        <h5 className="card-title">{name}:</h5>
                        <h1 className="card-text">{currentCount}{adder}</h1>
                    </div>
                </div>
            </div>
        </div>
    );
}
