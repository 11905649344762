import React, { useEffect, useState, useRef } from 'react';
import logo from '../assets/download.png';
import hamburger from '../assets/hamburger.png';
import { Link } from 'react-router-dom';
import ToggleBarNav from './ToggleBarNav';
import '../assets/Css/Navbar.css';

export default function Navbar() {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleRef = useRef(null);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const handleResize = () => {
        setIsMobile(window.innerWidth < 1731);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (toggleRef.current && !toggleRef.current.contains(event.target)) {
                closeMenu();
            }
        };

        if (menuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuOpen]);

    return (
        <>
            <nav className={`${isScrolled ? 'scrolled' : ''} navbar navbar-expand-lg sticky-navbar custom-navbar`}>
                <div className="navbar-logo">
                    <Link className="navbar-brand" to='/'>
                        <img src={logo} alt="Logo" className="navbar-logo-img" />
                    </Link>
                </div>
                <div className="custom-navbar-container">
                    <div className='toggle' id="navbarNav">
                        {isMobile ? (
                            <img
                                src={hamburger}
                                alt="Hamburger Menu"
                                onClick={toggleMenu}
                                className="toggle"
                            />
                        ) : (
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <Link className="nav-link navbar-fonts" aria-current="page" to='/'>HOME</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link navbar-fonts" to='/About'>ABOUT US</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link navbar-fonts" to='/WebsiteContent'>WEBSITE CONTENT</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link navbar-fonts" to='/SearchEngineOptimization'>SEO CONTENT</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link navbar-fonts" to='/AcademicTutoring'>ACADEMIC TUTORING</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link navbar-fonts" to='/GraphicDesigning'>GRAPHIC DESIGNING</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link navbar-fonts" to='/SocialMediaMarketing'>SOCIAL MEDIA</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link navbar-fonts" to='/Contact'>CONTACT US</Link>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </nav>
            {menuOpen && <div className="body-overlay" onClick={closeMenu} />}
            <ToggleBarNav
                closeMenu={closeMenu}
                className={menuOpen ? 'open' : ''}
                ref={toggleRef}
            />
        </>
    );
}
