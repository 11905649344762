import React from 'react';
import WebsiteBg from '../assets/websiteBg.jpg';

import '../assets/Css/WebsiteContent.css';
export default function WebsiteContent(props) {
    const { name } = props;
    return (
        <div style={{
            backgroundColor: '#617083',
            position: 'relative',
            overflow: 'hidden',
            marginTop: '0'
        }}>
            <img
                src={WebsiteBg}
                alt="Scramblent Background Cover"
                className="website-content-img"
                style={{
                    width: '100vw',
                    objectFit: 'cover',
                    opacity: 0.4
                }}
            />
            <h1 className="website-content-title">

                SCRAMBLENT
            </h1>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: '70%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                flexDirection: 'column',
                overflow: 'hidden'
            }}>
                <h1
                    style={{
                        fontSize: '5vw',
                        fontStyle: 'italic',
                        color: 'white',
                        fontFamily: 'fantasy',
                        paddingBottom: '5px',
                        textShadow: `
                                -2px -2px 0 #000,  
                                2px -2px 0 #000,
                                -2px 2px 0 #000,
                                2px 2px 0 #000`
                    }}
                >
                    {name}
                </h1>
            </div>
        </div>
    );
}
