import React from 'react'

import '../assets/Css/BottomSecondSame.css';
export default function AboutUs(props) {
    const { image, para1, para2, heading1, heading2 } = props
    return (
        <div className="mb-3">
            <div className="row g-0">
                <div className="col-lg-6 col-md-8 col-sm-12 d-flex justify-content-center align-items-center">
                    <div className="card-body text-center marginal">

                        <h5 className="card-title text-center">{heading1}</h5>
                        <h1 className="text-center">{heading2}</h1>
                        <hr style={{
                            border: '0',
                            borderTop: '5px solid #000',
                            marginTop: '10px',
                            marginBottom: '10px',
                            marginLeft: '45%',
                            width: '10%'
                        }} />
                        <p className="card-text m-5">{para1}</p>
                        <p className="card-text m-5">{para2}</p>
                    </div>
                </div>

                <div className="col-lg-6 col-md-4 col-sm-12 d-flex justify-content-center align-items-center">
                    <img
                        src={image}
                        className="img-fluid rounded-start"
                        alt="About Us"
                        style={{
                            width: '100%',
                            maxWidth: '500px',
                        }}
                    />
                </div>

            </div>
        </div>
    )
}
