import React, { useEffect } from 'react'
import WelcomeDesc from './WelcomeDesc'
import smmPage from '../assets/smm.png'
import Offers from './Offers'
import WebsiteContent from './WebsiteContent';


import { Link } from 'react-router-dom';

const arr = [
    {
        id: 1,
        packageName: 'GOLD',
        price: 99,
        metric: 'Month',
        background: 'linear-gradient(to right, white, #ffe971)',
        points: [
            'Social Media Marketing Plan',
            'Manage Facebook Page',
            'Weekly 1 FB Posts',
            'Manage Twitter Page',
            'Weekly 1 Twitter Posts',
            'Twitter Background Design',
            'Weekly 1 LinkedIn Posts',
            'Manage Instagram Account',
            'Weekly 1 Instagram Post',
        ]
    },
    {
        id: 2,
        packageName: 'PROFESSIONAL',
        price: 299,
        metric: 'Month',
        background: 'linear-gradient(to right, white, pink)',
        points: [
            'Social Media Marketing Plan',
            'Manage Facebook Page',
            'Weekly 2 FB Posts',
            'Manage Twitter Page',
            'Weekly 2 Twitter Posts',
            'Twitter Background Design',
            'Weekly 2 LinkedIn Posts',
            'Manage Instagram Account',
            'Weekly 2 Instagram Post',
        ]
    },

]

export default function SmmPage() {
    useEffect(() => {
        document.title = 'Scramblent | SMM';
    }, []);
    return (
        <>
            <WebsiteContent name="SMM Content" />
            <div className='m-3' style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='m-1' >
                    <WelcomeDesc image={smmPage} heading2="SOCIAL MEDIA MARKETING" heading1="Discover services we’r provided" para1="In the current digital landscape, businesses face challenges in connecting with their target audience and driving engagement. Social Media Marketing (SMM) offers a powerful solution by leveraging platforms like Facebook, Instagram, and Twitter to reach potential customers where they spend most of their time." para2="We focus on creating tailored content that resonates with your audience, fostering meaningful interactions and building a strong online community. With our expertise, your business can effectively convert followers into loyal customers, driving growth and success in the competitive digital marketplace." />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
                    {
                        arr.map((elem) => {
                            return (
                                <Link key={elem.id} to='/Contact' style={{ textDecoration: 'none', color: 'black' }}>
                                    <div className='overall m-4'  style={{
                                        background: `${elem.background}`,
                                        border: '1px solid black',
                                        borderRadius: '20px'
                                    }}
                                    >
                                        <Offers packages={elem.points} price={elem.price} name={elem.packageName}
                                            metric={elem.metric}
                                        />
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>

            </div>
        </>

    )
}
