import React, { useEffect } from 'react'
import WebsiteContent from './WebsiteContent';
import '../assets/Css/BottomSecondSame.css';

const arr = [
    {
        id: 1,
        heading: 'Cancellation due to quality of service',
        content: 'If for any reason you are not entirely satisfied with our service then you can cancel the contract within the first 15 days from the Commencement (initiation of project) date, and we will refund the unused amount of your fees in full for Prepaid plans, and refund any Milestone (for fixed price projects) that was paid for but not delivered yet.For example, if you have paid for a prepaid hourly plan of 100 hours per month, and you wish to cancel after 7 days after the start of contract where 20 hours were utilized on your project, we will refund the 80 hours that were not used.To file a Refund Request, you will need to email us at:info@scramblent.com'
    }
]

export default function RefundPolicy() {
    useEffect(() => {
        document.title = 'Scramblent | Refund Policy';
    }, []);
    return (
        <>
            <WebsiteContent name="Refund Policy" />
            <div className='container' style={{
                width: '80vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginTop: '30px'
            }}>
                {arr.map((elem) => (
                    <div className='m-2' key={elem.id}>
                        <h4>{elem.heading}</h4>
                        <p className='marginal2' style={{ fontStyle: 'italic' }}>{elem.content}</p>
                        <hr></hr>
                    </div>
                ))}
            </div>
        </>

    )
}
