import React, { useEffect } from 'react'

import WebsiteContent from './WebsiteContent'
import Contact from '../assets/contact.png'
import ContactUs from './ContactUs'
export default function AboutPage() {
    useEffect(() => {
        document.title = 'Scramblent | Contact Us';
    }, []);
    return (
        <>

            <WebsiteContent name="Contact Us" />
            <div className='m-3' style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='m-1' >
                    <ContactUs image={Contact} heading1="Contact" heading2="SCRAMBLENT" para1="Scramblent is a leading IT agency dedicated to providing a comprehensive range of IT services under one roof. In response to the growing demand for endless digital solutions, we at Scramblent are committed to delivering exactly that—unlimited, innovative services tailored to our clients' needs. We proudly offer SEO and social media marketing and management services. Our expert team takes the burden off our clients by handling every aspect of social media marketing and management. From PPC campaigns and page boosting to post promotions and comprehensive campaign creation, we offer end-to-end solutions that allow our clients to efficiently outsource their social media needs." para2="Our development and design services encompass everything from website and application design to full-scale development. Our passionate team is dedicated to providing unmatched quality, always ready to guide and support our clients through every step of the process." />
                </div>
            </div>


        </>
    )
}
