import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Reviews from './Reviews';
import reviewDp from '../assets/reviewsDP.png';
import Companies from './Companies';
import '../assets/Css/BottomSecondSame.css';
const reviews = [
    {
        id: 1,
        reviewerName: 'Abdul Aziz',
        review: 'Choosing Scramblent as our Software Solution partner has proven to be a great decision. They are a top-tier web development company in Pakistan, consistently delivering professional services.'
    },
    {
        id: 2,
        reviewerName: 'Max Doe',
        review: 'We have been thoroughly impressed with Scramblents\' technical expertise, encompassing both technical research and design, as well as their programming skills.'
    },
    {
        id: 3,
        reviewerName: 'Sara Ikram',
        review: 'Scramblent boasts an impressive team that consistently delivers my projects on time. Their professional attitude in customer interactions is truly commendable.'
    },
];

export default function BottomSecondSame() {
    return (
        <>
            <div style={{
                backgroundColor: 'black',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '20px',
                marginBottom: '20px'
            }}>
                <Companies />
            </div>

            <div style={{ width: '80vw', margin: '0 auto', marginBottom: '40px' }}>
                <Carousel
                    infiniteLoop={true}
                    showThumbs={false}
                    autoPlay={true}
                    interval={2000}
                    showStatus={false}
                    showArrows={false}
                    showIndicators={true}
                >
                    {reviews.map((elem) => {
                        return (
                            <div className='marginal' key={elem.id} >
                                <Reviews image={reviewDp} heading1={elem.reviewerName} para1={elem.review} />
                            </div>
                        );
                    })}
                </Carousel>
            </div>
        </>
    );
}
